import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { appInsightsConfig } from '../appConfig';

class ApplicationInsightsService {
    appInsights: ApplicationInsights;
    reactPlugin: ReactPlugin;

    constructor() {
        this.reactPlugin = new ReactPlugin();
        this.appInsights = new ApplicationInsights({
            config: {
                connectionString: appInsightsConfig.connectionString,
                extensions: [this.reactPlugin],
                enableAutoRouteTracking: true,
                disableAjaxTracking: false,
                autoTrackPageVisitTime: true,
                enableCorsCorrelation: true,
                enableRequestHeaderTracking: false,
                enableResponseHeaderTracking: false,
            },
        });
        if (appInsightsConfig.connectionString) {
            this.appInsights.loadAppInsights();
        }
    }
}
const appInsightsService = new ApplicationInsightsService();
export default appInsightsService;
