import { SeverityLevel } from '@microsoft/applicationinsights-web';
import appConfig from '../appConfig';
import MsalClientBase from './MsalClientBase';
import {
    ApiNonSuccessError,
    ApiRequest,
    Application,
    ODataQuery,
    AppType,
    AppState,
    Team,
    UpdateApplicationRequest,
    EnvironmentType,
    AppRegistrationType,
    AttributeType,
    AttributeValue,
    ApplicationPermission,
    AttributeKey,
    CreateApplicationRequest,
    CodeType,
    CreateAppRegistrationRequest,
    AppRegistration,
    AppRegistrationResponse,
    LinkAppRegistrationRequest,
    EventLog,
    Department,
    AppSourceType,
    AppSource,
    System,
    SystemPermission,
    CreateSystemRequest,
    SearchResult,
    UpdateSystemRequest,
    ConsolidateApplicationRequest,
    ConsolidateApplicationResponse,
    ApplicationEndpoint,
    ApplicationEndpointRequest,
    Group,
} from './models';
import { toast } from 'react-toastify';
import appInsightsService from '../appInsights/ApplicationInsightsService';

class AppRegistryClient extends MsalClientBase {
    constructor() {
        super(appConfig.apiBaseUrl, [appConfig.apiScope]);
    }

    private callApi = async <T>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', body?: any): Promise<T> => {
        const request: ApiRequest = {
            url: path,
            method: method,
            body: body,
        };

        try {
            return await this.fetchJson(request);
        } catch (error) {
            this.notifyError(error);
            throw error;
        }
    };

    private callApiNullable = async <T>(path: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH', body?: Promise<T>) => {
        const request: ApiRequest = {
            url: path,
            method: method,
            body: body,
        };
        try {
            return await this.fetchJsonNullable<T>(request);
        } catch (error) {
            this.notifyError(error);
            throw error;
        }
    };

    private notifyError = (error: unknown) => {
        const apiError = error as ApiNonSuccessError;
        const message = `Error (${apiError?.status}) while calling API. Message: ${apiError?.message}.`;
        toast.error(message);

        appInsightsService.appInsights.trackException({ error: error as Error, severityLevel: SeverityLevel.Error });
    };

    private get = async <T>(path: string, body?: any): Promise<T> => {
        return this.callApi(path, 'GET', body);
    };

    private post = async <T>(path: string, body?: any): Promise<T> => {
        return this.callApi(path, 'POST', body);
    };

    private delete = async (path: string, body?: any) => {
        return this.callApiNullable(path, 'DELETE', body);
    };

    private put = async (path: string, body?: any) => {
        return this.callApiNullable(path, 'PUT', body);
    };

    private patch = async <T>(path: string, body?: any) => {
        return this.callApi<T>(path, 'PATCH', body);
    };

    private deleteWithResponse = async <T>(path: string, body?: any): Promise<T> => {
        return this.callApi(path, 'DELETE', body);
    };

    private getSingleOrUndefined = async <T>(array: T[]): Promise<T | undefined> => {
        if (array.length !== 1) {
            return undefined;
        }
        return array[0];
    };

    private getODataQueryString = (criteria: ODataQuery | null) => {
        const values = {
            filter: criteria?.filter ? `$filter=${criteria.filter}` : '',
            select: criteria?.select ? `$select=${criteria.select}` : '',
            skip: criteria?.skip ? `$skip=${criteria.skip}` : '',
            top: criteria?.top ? `$top=${criteria.top}` : '',
            orderBy: criteria?.orderBy ? `$orderby=${criteria.orderBy}` : '',
            expand: criteria?.expand ? `$expand=${criteria.expand}` : '',
        };

        let query = '';
        let key: keyof typeof values;
        for (key in values) {
            const value = values[key];
            if (value) {
                query += `${value}&`;
            }
        }
        return query;
    };

    getAppTypeById = async (id: string): Promise<AppType> => {
        const result = await this.get<AppType>(`application-types/${id}`);
        return result;
    };

    getAllAppTypes = async (): Promise<AppType[]> => {
        const result = await this.get<AppType[]>(`application-types`);
        return result;
    };

    getAppStateById = async (id: string): Promise<AppState> => {
        const result = await this.get<AppState>(`application-states/${id}`);
        return result;
    };

    getAllAppStates = async (): Promise<AppState[]> => {
        const result = await this.get<AppState[]>(`application-states`);
        return result;
    };

    getTeamById = async (id: string): Promise<Team> => {
        const result = await this.get<Team>(`teams/${id}`);
        return result;
    };

    getAllTeams = async (): Promise<Team[]> => {
        const result = await this.get<Team[]>(`teams`);
        return result;
    };

    getAllSystems = async (): Promise<System[]> => {
        const result = await this.get<System[]>(`systems`);
        return result;
    };

    updateApplication = async (id: string, updateRequest: UpdateApplicationRequest) => {
        await this.put(`applications/${id}`, updateRequest);
    };

    consolidationCandidates = async (criteria: ODataQuery | null, displayNames: string[], matchScore: number) => {
        const query = appRegistryClient.getODataQueryString(criteria);
        const body = {
            appDisplayNames: displayNames,
            minimumMatchScoreCutoff: matchScore,
        };
        const url = `applications/find-candidates?${query}`;
        const result = await this.post<Application[]>(url, body);
        return result;
    };

    get userPermissions() {
        return {
            getApplicationPermissions: async (appId: string) => {
                const result = await this.get<string[]>(`permissions/userPermissions/application/${appId}`);
                return result;
            },
            getApplicationAttributesPermissions: async (appId: string) => {
                const result = await this.get<AttributeType[]>(`permissions/userPermissions/application/${appId}/attributes`);
                return result;
            },
            getRootPermissions: async () => {
                const result = await this.get<string[]>(`permissions/userPermissions/root`);
                return result;
            },
            getSystemPermissions: async (sysId: string) => {
                const result = await this.get<string[]>(`permissions/userPermissions/system/${sysId}`);
                return result;
            },
        };
    }

    get appRegistrationTypes() {
        return {
            getAll: async () => {
                const result = await this.get<AppRegistrationType[]>(`app-registration-types`);
                return result;
            },
        };
    }

    get appRegistrationConfigurations() {
        return {
            toClient: async (appId: string, appRegId: string) => {
                const url = `applications/${appId}/app-registrations/${appRegId}/to-client`;
                return await this.patch<AppRegistrationResponse>(url);
            },
            toWebapi: async (appId: string, appRegId: string, identifierUri: string) => {
                const url = `applications/${appId}/app-registrations/${appRegId}/to-webapi`;
                const request = { identifierUris: [identifierUri] };
                return await this.patch<AppRegistrationResponse>(url, request);
            },
            toSpa: async (appId: string, appRegId: string, redirectUris: string[]) => {
                const url = `applications/${appId}/app-registrations/${appRegId}/to-spa`;
                const request = { redirectUris: redirectUris };
                return await this.patch<AppRegistrationResponse>(url, request);
            },
            toWebApp: async (appId: string, appRegId: string, identifierUri: string, redirectUris: string[]) => {
                const url = `applications/${appId}/app-registrations/${appRegId}/to-webapp`;
                const request = { redirectUris: redirectUris, identifierUris: [identifierUri] };
                return await this.patch<AppRegistrationResponse>(url, request);
            },
        };
    }

    get departments() {
        return {
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `departments?${query}`;
                return this.get<Department[]>(url);
            },
        };
    }

    get environmentTypes() {
        return {
            getAll: async () => {
                const result = await this.get<EnvironmentType[]>(`environment-types`);
                return result;
            },
        };
    }

    get applications() {
        return {
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `Applications?${query}`;
                return this.get<Application[]>(url);
            },
            post: (request: CreateApplicationRequest) => {
                const url = `Applications`;
                return this.post(url, request);
            },
            consolidation: (request: ConsolidateApplicationRequest) => {
                const url = `Applications/consolidation`;
                return this.post<ConsolidateApplicationResponse>(url, request);
            },
        };
    }

    get myApplications() {
        return {
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `applications/my?${query}`;
                return this.get<Application[]>(url);
            },
        };
    }

    get applicationsPermissions() {
        return {
            get: (appId: string) => {
                const criteria = { expand: 'group' } as ODataQuery;
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `applications/${appId}/permissions?${query}`;
                return this.get<ApplicationPermission[]>(url);
            },
            delete: (appId: string, groupId: string, role: string) => {
                const url = `applications/${appId}/permissions`;
                const request = {
                    groupId: groupId,
                    role: role,
                };
                return this.delete(url, request);
            },
            post: (appId: string, groupId: string, role: string): Promise<ApplicationPermission> => {
                const url = `applications/${appId}/permissions`;
                const request = {
                    groupId: groupId,
                    role: role,
                };
                return this.post<ApplicationPermission>(url, request);
            },
        };
    }

    get systemsPermissions() {
        return {
            get: (systemId: string) => {
                const criteria = { expand: 'group' } as ODataQuery;
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `systems/${systemId}/permissions?${query}`;
                return this.get<SystemPermission[]>(url);
            },
            delete: (systemId: string, groupId: string, role: string) => {
                const url = `systems/${systemId}/permissions`;
                const request = {
                    groupId: groupId,
                    role: role,
                };
                return this.delete(url, request);
            },
            post: (systemId: string, groupId: string, role: string): Promise<SystemPermission> => {
                const url = `systems/${systemId}/permissions`;
                const request = {
                    groupId: groupId,
                    role: role,
                };
                return this.post<SystemPermission>(url, request);
            },
        };
    }

    get permissionGroups() {
        return {
            search: (searchTerm: string) => {
                const url = `permission-groups/search?searchTerm=${searchTerm}`;
                return this.get<SearchResult[]>(url);
            },
        };
    }

    get users() {
        return {
            search: (searchTerm: string) => {
                const url = `users/search?searchTerm=${searchTerm}`;
                return this.get<SearchResult[]>(url);
            },
        };
    }

    get applicationRoles() {
        return {
            get: () => {
                return this.get<{ [key: string]: string }>(`application-roles`);
            },
        };
    }

    get systemRoles() {
        return {
            get: () => {
                return this.get<{ [key: string]: string }>(`system-roles`);
            },
        };
    }

    get attributeTypes() {
        return {
            getAll: async () => {
                const result = await this.get<AttributeType[]>(`attribute-types`);
                return result;
            },
            post: async (attributeType: AttributeType) => {
                const result = await this.post<AttributeType>(`attribute-types`, attributeType);
                return result;
            },
            put: async (attributeType: AttributeType) => {
                const result = await this.put(`attribute-types/${attributeType.id}`, {
                    name: attributeType.name,
                    allowMultiple: attributeType.allowMultiple,
                    requiredRight: attributeType.requiredRight,
                    valueType: attributeType.valueType,
                });
                return result;
            },
            disable: async (id: string) => {
                const result = await this.put(`attribute-types/${id}/disable`);
                return result;
            },
            enable: async (id: string) => {
                const result = await this.put(`attribute-types/${id}/enable`);
                return result;
            },
            delete: async (id: string) => {
                await this.delete(`attribute-types/${id}`);
            },
        };
    }

    get attributeValues() {
        return {
            getAll: async () => {
                const result = await this.get<AttributeValue[]>(`attribute-values`);
                return result;
            },
            post: async (attributeValue: AttributeValue): Promise<AttributeValue> => {
                const result = await this.post<AttributeValue>(`attribute-values`, attributeValue);
                return result;
            },
            put: async (attributeValue: AttributeValue) => {
                const result = await this.put(`attribute-values/${attributeValue.id}`, { name: attributeValue.name });
                return result;
            },
            delete: async (id: string) => {
                await this.delete(`attribute-values/${id}`);
            },
            setActive: async (attributeValue: CodeType) => {
                await this.put(`attribute-values/${attributeValue.id}/${attributeValue.isActive ? 'enable' : 'disable'}`);
            },
        };
    }

    get applicationAttributes() {
        return {
            post: async (applicationId: string, applicationAttributes: AttributeKey[]) => {
                await this.post(`applications/${applicationId}/attributes`, { attributes: applicationAttributes });
            },
        };
    }

    get codeValues() {
        return {
            getAll: async (codeTypeId: string) => {
                const result = await this.get<CodeType[]>(codeTypeId);
                return result;
            },
            post: async (codeTypeId: string, codeValue: CodeType) => {
                const result = await this.post<CodeType>(codeTypeId, codeValue);
                return result;
            },
            put: async (codeTypeId: string, codeValue: CodeType) => {
                await this.put(`${codeTypeId}/${codeValue.id}`, codeValue);
            },
            delete: async (codeTypeId: string, codeValueId: string) => {
                await this.delete(`${codeTypeId}/${codeValueId}`);
            },
            setActive: async (codeTypeId: string, codeValue: CodeType) => {
                await this.put(`${codeTypeId}/${codeValue.id}/${codeValue.isActive ? 'enable' : 'disable'}`);
            },
        };
    }

    get appRegistrations() {
        return {
            getAll: async () => {
                const result = await this.get<AppRegistration[]>(`app-registrations`);
                return result;
            },
            getFromAzure: async (searchTerm: string) => {
                const result = await this.get<AppRegistration[]>(`app-registrations-from-azure?searchTerm=${searchTerm}`);
                return result;
            },
            delete: async (applicationId: string, appRegistrationId: string): Promise<AppRegistrationResponse> => {
                return await this.deleteWithResponse<AppRegistrationResponse>(`applications/${applicationId}/app-registrations/${appRegistrationId}`);
            },
            post: async (applicationId: string, request: CreateAppRegistrationRequest) => {
                const url = `applications/${applicationId}/app-registrations`;
                return this.post<AppRegistrationResponse>(url, request);
            },
            link: async (applicationId: string, clientId: string, request: LinkAppRegistrationRequest) => {
                const url = `applications/${applicationId}/app-registrations/${clientId}/from-azure-ad`;
                return this.post<AppRegistrationResponse>(url, request);
            },
            unlink: async (applicationId: string, appRegistrationId: string) => {
                const url = `applications/${applicationId}/app-registrations/${appRegistrationId}/unlink`;
                return this.deleteWithResponse<AppRegistrationResponse>(url);
            },
        };
    }

    get eventLogs() {
        return {
            getByApplicationId: async (applicationId: string, criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `event-log/by-application-id/${applicationId}/?${query}`;
                return this.get<EventLog[]>(url);
            },
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `event-log?${query}`;
                return this.get<EventLog[]>(url);
            },
        };
    }

    get appSourceTypes() {
        return {
            getAll: async () => {
                const result = await this.get<AppSourceType[]>(`discovery-sources`);
                return result;
            },
        };
    }

    get appSources() {
        return {
            getByApplicationId: async (applicationId: string) => {
                const url = `applications/${applicationId}/sources`;
                return this.get<AppSource[]>(url);
            },
        };
    }

    get systems() {
        return {
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `systems?${query}`;
                return this.get<System[]>(url);
            },
            post: (request: CreateSystemRequest) => {
                const url = `systems`;
                return this.post(url, request);
            },
            put: (id: string, request: UpdateSystemRequest) => {
                const url = `systems/${id}`;
                return this.put(url, request);
            },
        };
    }

    get mySystems() {
        return {
            get: (criteria: ODataQuery | null) => {
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `systems/my?${query}`;
                return this.get<System[]>(url);
            },
        };
    }

    get applicationsEndpoints() {
        return {
            get: (applicationId: string, criteria: ODataQuery | null = null) => {
                let url = `applications/${applicationId}/endpoints`; //?${query}
                if (criteria) {
                    const query = appRegistryClient.getODataQueryString(criteria);
                    url += `?${query}`;
                }
                return this.get<ApplicationEndpoint[]>(url);
            },
            post: (applicationId: string, request: ApplicationEndpointRequest) => {
                const url = `applications/${applicationId}/endpoints`;
                return this.post(url, request);
            },
            put: (applicationId: string, endpointId: string, request: ApplicationEndpointRequest) => {
                const url = `applications/${applicationId}/endpoints/${endpointId}`;
                return this.put(url, request);
            },
            delete: async (applicationId: string, endpointId: string) => {
                const url = `applications/${applicationId}/endpoints/${endpointId}`;
                await this.delete(url);
            },
        };
    }

    get groupMembers() {
        return {
            get: (groupId: string) => {
                const criteria = { filter: `id eq '${groupId}'`, expand: 'members' } as ODataQuery;
                const query = appRegistryClient.getODataQueryString(criteria);
                const url = `permission-group-cache?${query}`;
                return this.get<Group[]>(url);
            },
        };
    }
}
const appRegistryClient = new AppRegistryClient();
export default appRegistryClient;
